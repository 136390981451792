/* Ensures the container takes up the full viewport */
body, html, #root, .container {
  height: 100%;
  margin: 0;
}

.container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.left-section, .right-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cover-image {
  max-width: 100%;
  max-height: 80vh; /* Adjusts to fit within the viewport */
  object-fit: cover;
}

/* Content alignment */
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns everything to the start, keeping it uniform */
  text-align: left;
  max-width: 900px;
}

.main-heading {
  font-size: 5.8em;
  font-weight: bold;
  margin-bottom: 10px;
}

.sub-description {
  font-size: 1.5em;
  line-height: 1.5;
  color: #555;
  margin-bottom: 15px;
}

.platform-icons {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.icon {
  width: 40px;
  height: 40px;
}

.listen-now {
  width: 370px;
  height: 60px;
  background-color: #333;
  color: #fff;
  text-align: center;
  line-height: 60px;
  text-decoration: none;
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 15px;
  border-radius: 5px;
}

.additional-links a {
  font-size: 0.9em;
  color: #555;
  text-decoration: none;
}

.additional-links a:hover {
  text-decoration: underline;
}

/* Mobile layout */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .left-section, .right-section {
    width: 100%;
    justify-content: center;
  }

  .content-wrapper {
    padding: 0 20px;
    max-width: 90%; /* Ensure content fits well on smaller screens */
    text-align: center; /* Center-align content on mobile */
  }

  .main-heading {
    font-size: 1.6em;
    margin-top: 20px;
  }

  .sub-description {
    font-size: 1em;
    line-height: 1.4;
    margin-bottom: 20px;
  }

  .platform-icons, .social-icons {
    justify-content: center;
    gap: 15px;
  }

  .listen-now {
    width: 100%;
    max-width: 370px;
    height: 60px;
    margin: 20px 0;
  }

  .additional-links {
    text-align: center;
    margin-top: 20px;
  }
}